import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/EmailSignup"

export default function about(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="About" />

      <main className="markdown">
        <h1 className="text-center font-bold text-3xl">About Me</h1>
        <p>Hi, I'm Daniel.</p>
        <p>
          Since I was young, I've loved learning languages. I started off
          learning <b>Spanish</b> on my own in 8th grade, followed by{" "}
          <b>French</b>, <b>Italian</b>, and <b>German</b>.
        </p>
        <p>
          When I got to college, I wanted to try something new, so I decided to
          take <b>Russian</b>. I ended up double majoring in Neuroscience and
          Russian, and have been interested in the neuroscience of language
          learning ever since.
        </p>
        <p>
          I built Broca to share my language learning process with others. I
          share my thoughts and learnings from the week with my subscribers
          every Saturday. I'd love if you signed up for my newsletter at the
          bottom of this page.
        </p>
        <a href="https://monarchpatio.com">
          I currently run an online furniture store called Monarch Patio.
        </a>
        <h2 className="text-center font-bold text-3xl">
          What is{" "}
          <u>
            <i>Broca</i>
          </u>
          ?
        </h2>
        <p>
          <a href="https://en.wikipedia.org/wiki/Paul_Broca">Paul Broca</a> was
          a French physician and anatomist who is well known for his research on
          a region of the brain heavily involved with language processing.{" "}
          <a href="https://en.wikipedia.org/wiki/Broca%27s_area">
            Broca's area
          </a>
          , a region in the front part of the brain that is believe to be
          heavily involved in the production of speech.
        </p>
        <p>
          Broca studied several patients with damaged Broca's areas and found
          they had limited ability to produce language - in spoken and written
          forms, indicating that the area plays an important role in human
          language.
        </p>
        <p>
          I first learned about Paul Broca and language processing in my
          neurosciene classes at Duke.
        </p>
        <p className="italic">
          Note: The brain is a highly complex organ and it is a gross
          oversimplification to simply say that Broca's area alone is
          responsible for language production. Many areas of the brain
          contribute to language processing and production.
        </p>
        <div className="flex flex-col items-center">
          <EmailSignup></EmailSignup>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
